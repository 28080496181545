<template>
  <div>
    <h3 style="text-align: center">
      CONTABILIDADE - <u>{{ supplierData.name }}</u>
    </h3>
    <div id="addForm">
      <button class="btn btn-primary" v-b-modal.modal-add-accounting>
        Adicionar Transação
      </button>
    </div>
    <div class="calc">
      <span style="margin-right: 5px"
        ><b><u>Débito:</u></b>
        <span style="color: red"
          ><b>-{{ debit.toFixed(2) }}</b></span
        ></span
      >
      <span style="margin: 0px 5px"
        ><b><u>Crédito:</u></b>
        <span style="color: green"
          ><b>{{ credit.toFixed(2) }}</b></span
        ></span
      >
      <span style="margin-left: 5px"
        ><b><u>TOTAL:</u></b>
        <span
          :class="{
            'text-green': credit - debit > 0,
            'text-red': credit - debit < 0,
          }"
          ><b>{{ (credit - debit).toFixed(2) }}</b></span
        ></span
      >
    </div>
    <div
      class="row"
      style="justify-content: center; text-align: center; margin-top: 30px"
    >
      <div class="col-sm-2">
        <div class="form-group">
          <label for=""><b>Data Inicial</b></label> <br />
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="date_i"
            @change="accountingSupplier"
          />
        </div>
      </div>
      <div class="col-sm-2">
        <div class="form-group">
          <label for=""><b>Data Final</b></label> <br />
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="date_f"
            @change="accountingSupplier"
          />
        </div>
      </div>
    </div>
    <div class="info">
      <div class="card">
        <div class="card-body">
          <h5 style="color: rgb(255, 147, 147)"><b>Fornecedor</b></h5>
          <dl class="dl-horizontal">
            <dt>Nome:</dt>
            <dd class="truncate-text">{{ supplierData.name }}</dd>
            <dt>NIF:</dt>
            <dd class="truncate-text">{{ supplierData.nif }}</dd>
            <dt>Telefone:</dt>
            <dd class="truncate-text">{{ supplierData.contact }}</dd>
            <dt>Email:</dt>
            <dd class="truncate-text">{{ supplierData.email }}</dd>
            <dt>Tipo Fatura:</dt>
            <dd class="truncate-text">{{ supplierData.invoice_type }}</dd>
            <dt>Website:</dt>
            <dd class="truncate-text">{{ supplierData.url }}</dd>
          </dl>
        </div>
      </div>

      <table class="table table-bordered table-sm">
        <thead>
          <tr>
            <th scope="col">
              Tipo Doc.
              <input
                v-model="filters.type_document"
                type="text"
                placeholder="Search"
                id="search"
              />
            </th>
            <th scope="col">
              Detalhe
              <input
                v-model="filters.transaction_detail"
                type="text"
                placeholder="Search"
                id="search"
              />
            </th>
            <th scope="col">
              Débito
              <input
                v-model="filters.debit"
                type="text"
                placeholder="Search"
                id="search"
              />
            </th>
            <th scope="col">
              Credito
              <input
                v-model="filters.credit"
                type="text"
                placeholder="Search"
                id="search"
              />
            </th>
            <th scope="col">
              Receção
              <input
                v-model="filters.reception_id"
                type="text"
                placeholder="Search"
                id="search"
              />
            </th>
            <th scope="col" style="width: 200px">Data</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in filteredDatas" :key="index">
            <td>{{ data.type_document }}</td>
            <td>{{ data.transaction_detail }}</td>
            <td>{{ data.debit }}</td>
            <td>{{ data.credit }}</td>
            <td>
              <span v-if="data.pay_receptions == null">
                <a @click="openEditReceptionOrder(data.reception_id)">{{
                  data.reception_id
                }}</a>
              </span>
              <span v-else>
                <span v-for="reception in data.pay_receptions" :key="reception">
                  <a @click="openEditReceptionOrder(reception)">
                    {{ reception }} |</a
                  ></span
                >
              </span>
            </td>
            <td>{{ new Date(data.date_insert).toLocaleString("pt-PT") }}</td>
            <!-- Add more columns as needed -->
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal
      ref="modal-add-accounting"
      id="modal-add-accounting"
      title="Create Transactions"
      hide-footer
    >
      <form v-on:submit.prevent="addAccounting()">
        <div class="form-group">
          <label for="document">Document Type</label>
          <select
            class="form-control custom-select"
            id="document"
            v-model="document"
            @change="changeDocumentType"
          >
            <option value="Recebimento">Recebimento</option>
            <option value="Pagamento">Pagamento</option>
            <option value="Fatura">Fatura</option>
            <option value="Nota de Crédito">Notas de Crédito</option>
            <option value="Acerto Débito">Acerto Débito</option>
            <option value="Acerto Crédito">Acerto Crédito</option>
            <!-- <option value="Pagamentos Múltiplos">Pagamentos Múltiplos</option> -->
          </select>
        </div>
        <div class="form-group">
          <label for="value">Value</label>
          <input
            type="number"
            class="form-control"
            id="value"
            step="0.01"
            v-model="value"
            :class="{
              'is-invalid':
                document === 'Pagamentos Múltiplos' &&
                Math.abs(value - totalReceptions) > 1,
            }"
          />
        </div>
        <div class="form-group">
          <label for="provider">Receção</label>
          <v-select
            v-if="document === 'Pagamentos Múltiplos'"
            :options="allReceptions"
            v-model="reception_id"
            :reduce="(supp) => supp.id"
            label="bill_id"
            @input="calculateTotalReceptions"
            @search="searchBills"
            multiple
          >
            <template slot="option" slot-scope="option">
              {{ new Date(option.date_request).toLocaleDateString("pt-PT") }} -
              {{ option.bill_id }} - VR: {{ option.total }}€ - VF:
              {{ option.bill_value }}€
            </template>
            <template slot="selected-option" slot-scope="option">
              {{ new Date(option.date_request).toLocaleDateString("pt-PT") }} -
              {{ option.bill_id }} - VR: {{ option.total }}€ - VF:
              {{ option.bill_value }}€</template
            >
          </v-select>
          <v-select
            v-else
            :options="allReceptions"
            v-model="reception_id"
            :reduce="(supp) => supp.id"
            label="bill_id"
            @search="searchBills"
          >
            <template slot="option" slot-scope="option">
              {{ new Date(option.date_request).toLocaleDateString("pt-PT") }} -
              {{ option.bill_id }} - VR: {{ option.total }}€ - VF:
              {{ option.bill_value }}€
            </template>
            <template slot="selected-option" slot-scope="option">
              {{ new Date(option.date_request).toLocaleDateString("pt-PT") }} -
              {{ option.bill_id }} - VR: {{ option.total }}€ - VF:
              {{ option.bill_value }}€
            </template>
          </v-select>
        </div>
        <div
          class="form-group"
          v-if="document == 'Pagamentos Múltiplos' || document == 'Pagamento'"
        >
          <label for="exampleFormControlSelect1">Método de Pagamento</label>
          <select
            class="form-select form-select"
            id="exampleFormControlSelect1"
            v-model="invoice"
            style="font-size: 100%"
          >
            <option value=""></option>
            <option value="PayPal">PayPal</option>
            <option value="Referência MB">Referência MB</option>
            <option value="Cartão Crédito">Cartão Crédito</option>
            <option value="Transferência Bancária">
              Transferência Bancária
            </option>
            <option value="Revolut">Revolut</option>
          </select>
        </div>
        <div
          class="form-group"
          v-if="document == 'Fatura' || document == 'Nota de Crédito'"
        >
          <label for="invoice">Nº Fatura</label>
          <input
            id="invoice"
            class="form-control"
            type="text"
            v-model="invoice"
          />
        </div>
        <div class="form-group" v-else-if="document == 'Recebimento'">
          <label for="payment">Método de Receção</label>
          <select
            class="form-select form-select"
            id="exampleFormControlSelect1"
            v-model="invoice"
            style="font-size: 100%"
          >
            <option value=""></option>
            <option value="PayPal">PayPal</option>
            <option value="Referência MB">Referência MB</option>
            <option value="Cartão Crédito">Cartão Crédito</option>
            <option value="Transferência Bancária">
              Transferência Bancária
            </option>
            <option value="Revolut">Revolut</option>
          </select>
        </div>
        <div class="form-group" v-if="document == 'Acerto Débito' || document == 'Acerto Crédito'">
          <label for="fileInput">Descrição</label>
          <input
            type="text"
            class="form-control"
            id="textInput"
            v-model="invoice"
            placeholder="Ex: Desconto Comercial"
          />
        </div>
        <div class="form-group" v-if="document == 'Nota de Crédito'">
          <label for="fileInput">Inserir Nota de Crédito</label>
          <input
            type="file"
            class="form-control"
            id="fileInput"
            @change="handleFileCreditNoteUpload"
          />
          <input
            type="text"
            class="form-control"
            id="textInput"
            v-model="creditNoteURL"
            placeholder="Link da Fatura"
          />
        </div>
        <div class="form-group">
          <label for="dateInput">Data da Transação:</label>
          <input
            type="date"
            class="form-control"
            id="dateInput"
            v-model="dateTransaction"
          />
        </div>
        <div class="selectBtn">
          <button type="submit" class="btn btn-primary">
            Adicionar Transação
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      allDatas: [],
      document: "",
      value: 0,
      allReceptions: [],
      supplier: "",
      supplierData: [],
      debit: 0,
      credit: 0,
      invoice: "",
      reception_id: null,
      totalReceptions: "",
      creditNoteURL: "",
      creditNoteFile: "",
      date_i: "",
      date_f: "",
      filters: {
        type_document: "",
        transaction_detail: "",
        debit: "",
        credit: "",
        reception_id: "",
        // Add more filters as needed
      },
      dateTransaction: new Date().toISOString().substr(0, 10),
    };
  },
  methods: {
    async accountingSupplier() {
      try {
        await this.$store.dispatch("accountingBySupplier", {
          id: this.$route.params.id,
          date_start: this.date_i,
          date_end: this.date_f,
        });
        console.log("AQUI");
        this.allDatas = this.getAccountingBySupplier;
        console.log(this.getAccountingBySupplier);
        this.debit = this.allDatas.reduce((acc, data) => {
          console.log(data.debit);
          return acc + Number(data.debit);
        }, 0);
        this.credit = this.allDatas.reduce((acc, data) => {
          return acc + Number(data.credit);
        }, 0);
        console.log(this.debit);
      } catch (error) {
        alert("Error: " + error);
      }
    },
    async addAccounting() {
      console.log(this.reception_id);
      if (this.document == "Nota de Crédito") {
        if (!this.creditNoteURL && !this.creditNoteFile) {
          this.$swal({
            icon: "error",
            title: "Erro",
            text: "Tem de inserir um ficheiro da nota de crédito",
          });
          return;
        }
      }
      try {
        await this.$store.dispatch("addAccounting", {
          supplier: this.$route.params.id,
          document: this.document,
          value: this.value,
          reception_id: this.reception_id,
          invoice: this.invoice,
          date: this.dateTransaction,
        });
        await this.accountingSupplier();
        if (this.document == "Nota de Crédito") {
          await this.addCreditNoteFile();
        }
        this.document = "";
        this.value = 0;
        this.reception_id = "";
        this.invoice = "";
        this.dateTransaction = new Date().toISOString().substr(0, 10);
        await this.$refs["modal-add-accounting"].hide();
      } catch (error) {
        alert("Error: " + error);
      }
    },
    async accountingReceptions() {
      console.log("AQUI", this.$route.params.id);
      try {
        await this.$store.dispatch("accountingAllReceptions", {
          id: this.$route.params.id,
        });
        console.log("AQUI");
        this.allReceptions = this.getAccountingAllReceptions;
        console.log(this.allReceptions);
      } catch (error) {
        alert("Error: " + error);
      }
    },
    async getSupplierData() {
      try {
        await this.$store.dispatch("supplierByID", {
          id: this.$route.params.id,
        });
        this.supplierData = this.getSupplier;
        console.log(this.supplierData);
      } catch (error) {
        alert("Error: " + error);
      }
    },
    async changeDocumentType() {
      if (this.document == "Recebimento") {
        await this.receptionsReceive();
      } else if (this.document == "Nota de Crédito") {
        await this.receptionsCreditNote();
      } else if (
        this.document == "Pagamento" ||
        this.document == "Pagamentos Múltiplos"
      ) {
        await this.receptionsPayment();
      }
    },
    async receptionsReceive() {
      try {
        await this.$store.dispatch("receptionsToReceive", {
          id: this.$route.params.id,
        });
        console.log("AQUI");
        this.allReceptions = this.getReceptionsToReceive;
        console.log(this.getReceptionsToReceive);
      } catch (error) {
        alert("Error: " + error);
      }
    },
    async receptionsCreditNote() {
      try {
        await this.$store.dispatch("receptionsToCreditNote", {
          id: this.$route.params.id,
        });
        console.log("AQUI");
        this.allReceptions = this.getReceptionsToReceive;
        console.log(this.allReceptions);
      } catch (error) {
        alert("Error: " + error);
      }
    },
    async receptionsPayment() {
      try {
        await this.$store.dispatch("getReceptionsToPay", {
          id: this.$route.params.id,
        });
        this.allReceptions = this.getReceptionsToReceive;
        console.log(this.getReceptionsToReceive);
      } catch (error) {
        alert("Error: " + error);
      }
    },
    async searchBills(input) {
      console.log(input);
      try {
        await this.$store.dispatch("searchByBill", {
          id: this.$route.params.id,
          search: input,
        });
        console.log("AQUI");
        this.allReceptions = this.getReceptionsToReceive;
        console.log(this.getReceptionsToReceive);
      } catch (error) {
        alert("Error: " + error);
      }
    },
    async openEditReceptionOrder(id) {
      // this.$router.push(`/receptionProducts/${id}/update`);
      window.open(
        `https://${window.location.host}/receptionProducts/${id}/update`,
        "_blank"
      );
    },
    async calculateTotalReceptions() {
      let selectedReceptions = this.allReceptions.filter((reception) =>
        this.reception_id.includes(reception.id)
      );
      console.log(selectedReceptions);
      this.totalReceptions = selectedReceptions.reduce((acc, data) => {
        return acc + Number(data.total);
      }, 0);
    },
    async addCreditNoteFile() {
      console.log(this.creditNoteURL, this.creditNoteFile);
      if (!this.creditNoteURL && !this.creditNoteFile) {
        this.$swal({
          icon: "error",
          title: "Erro",
          text: "Tem de inserir um ficheiro da nota de crédito",
        });
      }
      try {
        let file = this.creditNoteURL;
        if (this.creditNoteFile) {
          file = this.creditNoteFile;
        }
        await this.$store.dispatch("addCreditNoteFile", {
          id: this.reception_id,
          file: file,
          creditNoteNumber: this.invoice,
          creditNoteValue: this.value,
          supplier: this.$route.params.id,
        });
        this.$swal("Ficheiro adicionado com sucesso", "", "success");
      } catch (err) {
        alert(err);
      }
    },
    handleFileCreditNoteUpload(event) {
      console.log(event.target.files[0]);
      this.creditNoteFile = event.target.files[0];
    },
  },
  async created() {
    await this.accountingSupplier();
    // await this.accountingReceptions();
    await this.getSupplierData();
  },
  computed: {
    ...mapGetters(["getAccountingBySupplier"]),
    ...mapGetters(["getAccountingAllReceptions"]),
    ...mapGetters(["getSupplier"]),
    ...mapGetters(["getReceptionsToReceive"]),
    filteredDatas() {
      return this.allDatas.filter((data) =>
        Object.keys(this.filters).every((key) =>
          String(data[key])
            .toLowerCase()
            .includes(this.filters[key].toLowerCase())
        )
      );
    },
  },
};
</script>

<style scoped>
.table {
  text-align: center;
  width: 60%;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}
td {
  font-size: 13px;
}
#addForm {
  text-align: center;
}
.card {
  border: none;
  margin-left: 50px;
}

dl {
  margin-left: 5px;
}

.info::before {
  content: "";
  border-left: 1px solid rgb(152, 152, 152); /* Adjust this as needed */
  height: 100%;
  position: absolute;
  left: 23%;
  top: 0;
}

.info {
  position: relative;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.calc {
  text-align: center;
  margin-top: 15px;
}

.text-green {
  color: green;
}
.text-red {
  color: red;
}
#search {
  width: 100%;
  height: 20px;
  font-size: 10px;
}

a:hover {
  cursor: pointer;
  text-decoration: underline !important;
}
.is-invalid {
  border-color: red;
}
.card-body{
  max-width: 315px;
}
</style>
